import { useState, useMemo, useEffect, DependencyList } from "react";

export type MediaQueryMatch<TValue extends {}> = { [query: string]: TValue };

export default function useMediaMatch<TValue>(
  matchObject: MediaQueryMatch<TValue>,
  deps: DependencyList = []
) {
  const mediaQueries = useMemo(
    () =>
      Object.entries(matchObject).reduce((map, [key, value]) => {
        const query = matchMedia(key);
        map.set(query.media, [query, value]);
        return map;
      }, new Map<string, [MediaQueryList, TValue]>()),
    [matchObject]
  );
  const [matches, setMatches] = useState(
    new Set<string>(
      Array.from(mediaQueries.values())
        .filter(([query]) => query.matches)
        .map(([query]) => query.media)
    )
  );
  useEffect(
    () =>
      mediaQueries.forEach(([query]) =>
        query.addListener(ev =>
          setMatches(matches => {
            const op: (value: string) => void = ev.matches
              ? matches.add
              : matches.delete;
            op.call(matches, query.media);
            //React-pleasing shallow-copy
            return new Set(matches);
          })
        )
      ),
    [mediaQueries]
  );
  return useMemo(() => {
    let result = [] as TValue[];
    matches.forEach(match => {
      const [, value] = mediaQueries.get(match) ?? [];
      if (value != null) {
        result.push(value);
      }
    });
    return result;
  }, [matches, mediaQueries]);
}

export const mediaQueries = {
  desktop: "only screen and (min-width: 769px)"
};
