import React, {
  CSSProperties,
  useMemo,
  forwardRef,
  ComponentPropsWithRef
} from "react";
import colors from "../colors";

const buttonStyle: CSSProperties = {
  backgroundColor: colors.purple,
  color: colors.white,
  padding: "12px 40px",
  border: 0,
  fontSize: "0.8em",
  fontWeight: 400,
  fontFamily: "SharpGrotesk",
  borderRadius: "24px",
  textDecoration: "none",
  transition: "color 300ms ease-out, background 300ms ease-out",
  cursor: "pointer",
};

const disabledStyle: CSSProperties = {
  backgroundColor: colors.gray,
  color: colors.grayDark,
  cursor: "initial"
};

const Button = forwardRef<HTMLButtonElement, ComponentPropsWithRef<"button">>(
  (props, ref) => {
    const style = useMemo(
      () => ({
        ...buttonStyle,
        ...(props.disabled ? disabledStyle : {}),
        ...props.style
      }),
      [props.style, props.disabled]
    );
    return (
      <button {...props} style={style} ref={ref}>
        {props.children}
      </button>
    );
  }
);

export const LinkButton = forwardRef<
  HTMLAnchorElement,
  ComponentPropsWithRef<"a">
>((props, ref) => (
  <a style={buttonStyle} {...props} ref={ref}>
    {props.children}
  </a>
));

export default Button;
