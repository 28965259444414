import React, { CSSProperties } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteChildrenProps,
} from "react-router-dom";
import "./App.css";
import SignupView from "./Components/SignupView";
import ContentContainer from "./Components/ContentContainer";
import ErrorBoundrary from "./Components/ErrorBoundrary";
import * as Sentry from "@sentry/browser";
import useMediaMatch, { mediaQueries } from "./Hooks/useMediaMatch";
import colors from "./colors";
import { LinkButton } from "./Components/Button";
import appleLogo from "./assets/apple-logo.png";
import googleLog from "./assets/google-play-logo.png";

Sentry.init({
  dsn: "https://422a53268d1d4a85aefd61612cf0bc92@sentry.io/2684616",
  enabled: process.env.NODE_ENV !== "development",
  environment: process.env.NODE_ENV,
});

const bgOverlay = colors.colorWithAlpha(colors.blue, 0.6);

const polyAppLink = "https://onelink.to/ty8m55";

const backgroundCoverStyle: CSSProperties = {
  zIndex: -1,
  position: "absolute",
  width: "100%",
  height: "100%",
  backgroundSize: "cover",
  backgroundPosition: "center",
  top: 0,
  left: 0,
};

const App = () => {
  const backgroundImage = useMediaMatch({
    [mediaQueries.desktop]: `url(/usBackground.jpg)`,
  })[0];

  const headerShadow = useMediaMatch({
    [mediaQueries.desktop]: "0 1px 3px rgba(0,0,0,0.4)",
  })[0];

  return (
    <ErrorBoundrary>
      <Router>
        <main style={{ position: "relative" }}>
          <header className="header" style={{ boxShadow: headerShadow }}>
            <img src="/usLogo.png" height="100%" alt="" />
          </header>
          <ContentContainer>
            <Switch>
              <Route path="/arenas/:arenaId/passes/:passId/invite/code/:token">
                <h1>Welcome!</h1>
                <SignupView />
              </Route>
              <Route path={["/user-created", "/access-granted"]}>
                {({
                  location: { state },
                }: RouteChildrenProps<{}, { firstName: string }>) => (
                  <>
                    <h1>
                      Thank you
                      {state && "firstName" in state
                        ? ` ${state.firstName}`
                        : ""}
                      !
                    </h1>
                    <p>
                      <Switch>
                        <Route path="/user-created">
                          You are now ready to start using our mobile app and
                          digital services. Download our app below and simply
                          sign in with the account you just created.
                        </Route>
                        <Route path="/access-granted">
                          Your account is ready. Simply download our app and log
                          in with your credentials. If you forgot your password,
                          you can reset it in the app.
                        </Route>
                      </Switch>
                    </p>
                    <p>
                      If you have any questions, don’t hesitate to contact{" "}
                      <a href="mailto:app@unitedspaces.se">
                        app@unitedspaces.se
                      </a>
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        margin: "3em 0",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                      }}
                    >
                      <a
                        href="https://apps.apple.com/us/app/united-spaces/id1250317933"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          height: 50,
                          display: "block",
                          overflow: "hidden",
                          marginBottom: 10,
                        }}
                      >
                        <img
                          src={appleLogo}
                          alt="App store"
                          style={{ maxHeight: "100%" }}
                        />
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.unitedspaces.USandroid&hl=sv"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          height: 50,
                          display: "block",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={googleLog}
                          alt="Play store"
                          style={{ maxHeight: "100%" }}
                        />
                      </a>
                    </p>
                  </>
                )}
              </Route>
              <Route path="/">
                <h1>
                  United Spaces <br /> Member sign up
                </h1>
                <p>
                  Oops, it looks like you visited this site without going
                  through a invite link required for sign-ups.
                </p>
                <p>
                  Having issues signing up? Contact{" "}
                  <a
                    href="mailto:app@unitedspaces.se"
                    style={{ color: colors.blue, fontWeight: 500 }}
                  >
                    app@unitedspaces.se
                  </a>
                </p>
              </Route>
            </Switch>
          </ContentContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              width: "100vw",
              background: colors.blue,
              position: "absolute",
              top: 0,
              zIndex: -1,
            }}
          >
            {backgroundImage && (
              <>
                <div style={{ ...backgroundCoverStyle, backgroundImage }} />
                <div
                  style={{
                    ...backgroundCoverStyle,
                    backgroundColor: bgOverlay,
                  }}
                />
              </>
            )}
          </div>
        </main>
      </Router>
    </ErrorBoundrary>
  );
};

export default App;
