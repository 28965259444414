import React, { useState, useCallback, useContext, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useAppusData, sendToAppus } from "../appusFetch";
import SignupForm from "./SignupForm";
import fadeSlideAnimation from "../animation/fadeSlideAnimation";
import { isValidEmail } from "../validations";
import UserFormData from "../Types/UserFormData";
import {
  CreateUserData,
  AuthResponse,
  ConnectUserData
} from "../Types/InviteData";
import { ErrorContext } from "./ErrorBoundrary";

type PassInvite = {
  company: { name: string };
  arena: { name: string };
  invites: [
    {
      email: string;
      code: string;
      inviter?: { firstName: string; lastName: string };
    }
  ];
};

type SubmitAction = "ACCESS_GRANTED" | "USER_CREATED";

type RouteParams = { token: string; arenaId: string; passId: string };

const SignupView = () => {
  const errorContext = useContext(ErrorContext);

  const { token, arenaId, passId } = useParams<RouteParams>();

  const { invites: inviteList, company, arena } =
    useAppusData<PassInvite>(
      `arenas/${arenaId}/passes/${passId}/invites/${token}?fields=arena,company,inviter`
    ) ?? {};

  const history = useHistory();
  const onSuccess = useCallback(
    (action: SubmitAction) => {
      history.push(
        action === "USER_CREATED" ? "/user-created" : "/access-granted"
      );
    },
    [history]
  );
  const invite = inviteList?.find(invite => invite.code === token);

  return invite ? (
    <>
      <div style={fadeSlideAnimation()}>
        <p>
          {invite.inviter?.firstName} {invite.inviter?.lastName} from{" "}
          {company?.name} has invited you to join {arena?.name}.
        </p>
      </div>
      <div style={{ minHeight: 400 }}>
        <InvitePresentation
          invite={invite}
          arenaId={arenaId}
          passId={passId}
          onSuccess={onSuccess}
        />
      </div>
    </>
  ) : errorContext.error === null ? (
    <div style={fadeSlideAnimation()}>
      <p>
        This invitation is no longer valid. Please contact your company
        representative.
      </p>
    </div>
  ) : null;
};

const InvitePresentation = (props: {
  invite: PassInvite["invites"][0];
  arenaId: string;
  passId: string;
  onSuccess(action: SubmitAction): void;
}) => {
  const [email, setEmail] = useState(props.invite.email);
  const [userCreated, setUserCreated] = useState(false);
  const { user: userExists } =
    useAppusData<{ user?: boolean }>(
      email != null
        ? `users/find?email=${encodeURIComponent(
            email
          )}&inviteCode=${encodeURIComponent(props.invite.code)}`
        : null,
      useMemo(() => ({ clearOnRefresh: false }), [])
    ) ?? {};

  const onEmailChange = (value: string) => {
    const isValid = isValidEmail(value);
    if (isValid) {
      setEmail(value);
    } else {
      // Would maybe like to set userExists = false here
    }
  };

  const onSubmit = (form: UserFormData) => {
    if (userExists) {
      // Connect to existing user
      const data: ConnectUserData = {
        code: props.invite.code,
        email: form.email
      };
      sendToAppus<ConnectUserData, PassInvite>(
        `arenas/${props.arenaId}/passes/${props.passId}/claim`,
        data,
        "PUT"
      )
        .then(() => {
          setUserCreated(true);
          props.onSuccess("ACCESS_GRANTED");
        })
        .catch(err => {
          setUserCreated(() => {
            throw err;
          });
        });
    } else {
      // Create new user
      const data: CreateUserData = {
        ...form,
        inviteCode: props.invite.code
      };

      sendToAppus<CreateUserData, AuthResponse>(`users`, data)
        .then(() => {
          setUserCreated(true);
          props.onSuccess("USER_CREATED");
        })
        .catch(err => {
          setUserCreated(() => {
            throw err;
          });
        });
    }
  };

  return !userCreated ? (
    <SignupForm
      email={email}
      userExists={userExists}
      onEmailChange={onEmailChange}
      onSubmit={onSubmit}
    ></SignupForm>
  ) : (
    <h1>Congratulations!</h1>
  );
};

export default SignupView;
