import React, { CSSProperties } from "react";

interface Props {
  htmlFor?: string;
  children: React.ReactNode;
}

const Label = (props: Props) => {
  const style: CSSProperties = {
    display: "block",
    fontSize: "0.85em",
    fontWeight: 400
  };
  return <label style={style} {...props} />;
};

export default Label;
