import {
  Component,
  ErrorInfo,
  PropsWithChildren,
  CSSProperties,
  createContext
} from "react";
import * as Sentry from "@sentry/browser";
import { AppusError } from "../appusFetch";
import React from "react";
import fadeSlideAnimation from "../animation/fadeSlideAnimation";

const errorBarStyle: CSSProperties = {
  position: "fixed",
  top: 0,
  display: "block",
  backgroundColor: "#e55",
  color: "#800",
  border: "0 1px 1px 1px solid #800",
  padding: "1em",
  textAlign: "center",
  zIndex: 2,
  width: "100%",
  ...fadeSlideAnimation("Down")
};

export const ErrorContext = createContext({ error: null as Error | null });

export default class ErrorBoundrary extends Component<PropsWithChildren<{}>> {
  state = { error: null as Error | null };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(info);
      Sentry.addBreadcrumb({
        category: "React",
        message: "Error caught by ErrorBoundrary",
        level: Sentry.Severity.Warning
      });
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <ErrorContext.Provider value={this.state}>
        {this.state.error && (
          <div style={errorBarStyle}>
            {`An error occured ${
              this.state.error instanceof AppusError
                ? "during network communication."
                : "."
            }`}
            <span
              style={{
                position: "absolute",
                right: 50,
                cursor: "pointer",
                padding: 10,
                marginTop: -10
              }}
              onClick={() => {
                this.setState({ error: null });
              }}
            >
              ╳
            </span>
          </div>
        )}
        {this.props.children}
      </ErrorContext.Provider>
    );
  }
}
