import React, { CSSProperties } from "react";
import Label from "./Label";
import colors from "../colors";
import fadeSlideAnimation from "../animation/fadeSlideAnimation";
import useMediaMatch, { mediaQueries } from "../Hooks/useMediaMatch";

interface Props {
  fieldType: "text" | "email" | "password";
  fieldName: string;
  fieldValue: string;
  required: boolean;
  labelText: string;
  autoComplete?: "on" | "off";
  error?: string | false;
  icon?: string;
  onChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
}

const inputStyle: CSSProperties = {
  flexGrow: 1,
  border: "none",
  borderBottom: `2px solid ${colors.gray}`,
  caretColor: colors.blueDark,
  fontSize: "1em",
  fontFamily: "SharpGrotesk",
  fontWeight: 300,
  lineHeight: "2em",
  outline: 0,
  color: colors.grayDark,
  padding: 0,
  borderRadius: 0
};

const iconStyle: CSSProperties = {
  maxWidth: 35,
  marginLeft: 5,
  ...fadeSlideAnimation("Left")
};

const FieldSet = (props: Props) => {
  const mediaIconStyle = useMediaMatch({
    [mediaQueries.desktop]: { ...iconStyle, marginRight: -35 },
    screen: iconStyle
  })[0];
  return (
    <fieldset style={{ marginBottom: `${1.45 - (props.error ? 1 : 0)}em` }}>
      <Label children={props.labelText} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <input
          autoComplete={props.autoComplete ?? "on"}
          required={props.required}
          type={props.fieldType}
          name={props.fieldName}
          value={props.fieldValue}
          onChange={props.onChange}
          onBlur={props.onBlur}
          style={inputStyle}
        />
        {props.icon && <img src={props.icon} style={mediaIconStyle} alt="" />}
      </div>
      {props.error && (
        <small style={{ color: "red", fontSize: "0.7em" }}>{props.error}</small>
      )}
    </fieldset>
  );
};

export default FieldSet;
