import { PropsWithChildren } from "react";
import React from "react";
import fadeSlideAnimation from "../animation/fadeSlideAnimation";

export default function ContentContainer({ children }: PropsWithChildren<{}>) {
  return (
    <>
      <div
        style={{
          maxWidth: 700,
          position: "relative",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          transition: "flex 300ms ease-out",
          overflow: "hidden",
          height: "calc(100vh - 76px)",
        }}
      >
        <div
          style={{
            padding: "0 1.5em",
            maxWidth: 400,
            margin: "3em auto",
            flexBasis: "45vh",
            zIndex: 2,
            ...fadeSlideAnimation("Right", 500),
          }}
        >
          {children}
        </div>
        <div
          style={{
            background: `white`,
            height: "100%",
            width: "100%",
            position: "absolute",
            mixBlendMode: "color-dodge",
            ...fadeSlideAnimation("Right", 500),
          }}
        >
          <svg
            viewBox="0 0 1 1"
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <rect width="1" height="1" fill="black" />
            <path
              fill="white"
              d="M 0,0
              Q .25,0 .25,.25
              L .25,.5
              Q .25,.75 .5,.75
              L .75,.75
              Q 1,.75 1,1
              L 0 1.1
              L 0 0"
            />
          </svg>
        </div>
      </div>
    </>
  );
}
