import { useState, useEffect, useContext } from "react";
import { ErrorContext } from "./Components/ErrorBoundrary";

export interface AppusFault {
  fault: {
    code: number;
    text: string;
    file?: string;
    line?: number;
  };
}

export class AppusError extends Error {
  response: Response;
  faultErr: AppusFault;

  constructor(faultErr: AppusFault, response: Response) {
    super(
      `API returned error: "${faultErr.fault.text}", error code: ${faultErr.fault.code}, http status: ${response.status}, URL: ${response.url}`
    );

    this.faultErr = faultErr;
    this.response = response;
  }
}

const ensureHttpSuccess = async (response: Response) => {
  if (!response.ok) {
    const clonedResponse = response.clone();
    const faultErr = (await response.json().catch(() => {})) as
      | AppusFault
      | undefined;
    throw faultErr != null
      ? new AppusError(faultErr, response)
      : new Error(
          `Http error ${response.status} while fetching from ${response.url}, ${
            response.statusText
          }, Body: ${await clonedResponse.text()}`
        );
  }
  return response;
};

const appusUrl = () => {
  //TODO: REACT_APP_APPUS_ENV is not working on netlify environments
  let appusEnv =
    process.env.REACT_APP_APPUS_ENV ?? process.env.NODE_ENV === "production"
      ? "live"
      : "play";
  return `https://api-dot-usapp-${appusEnv}.appspot.com`;
};

const defaultHeaders = [
  ["Accept", `application/vnd.unitedspaces.v7+json`],
  ["Content-Type", "application/json"]
];

export function getFromAppus<TData>(path: string, abort?: AbortController) {
  console.log(`Fetching from appus: ${path}`);
  return fetch(`${appusUrl()}/${path}`, {
    method: "GET",
    headers: defaultHeaders,
    signal: abort?.signal
  })
    .then(ensureHttpSuccess)
    .then(res => res.json())
    .then(json => json.data as TData);
}

export function sendToAppus<TData, TResponse>(
  path: string,
  data: TData,
  method: "POST" | "PUT" = "POST"
) {
  return fetch(`${appusUrl()}/${path}`, {
    method: method,
    headers: defaultHeaders,
    body: JSON.stringify({ data })
  })
    .then(ensureHttpSuccess)
    .then(res => res.json())
    .then(json => json.data as TResponse);
}

const defaultOptions = { clearOnRefresh: true };

export function useAppusData<TData>(
  path?: string | null,
  options?: Partial<typeof defaultOptions>
) {
  const [data, setData] = useState<TData>();
  const errorContext = useContext(ErrorContext);
  useEffect(() => {
    if (path == null || errorContext.error !== null) {
      return;
    }
    const { clearOnRefresh } = { ...defaultOptions, ...options };
    const abortController = new AbortController();
    getFromAppus<TData>(path, abortController)
      .then(setData)
      .catch(err => {
        if (err.name === "AbortError") {
          return;
        }
        //This is not an antipattern as long as we're throwing here.
        setData(() => {
          throw err;
        });
      });
    return () => {
      clearOnRefresh && setData(undefined);
      abortController.abort();
    };
  }, [path, errorContext.error, options]);
  return data;
}
