import { useState, useCallback, SyntheticEvent } from "react";
import UserFormData from "../Types/UserFormData";
import { typedKeys } from "../utils";

export const useSignupForm = (callback: () => void, defaultEmail?: string) => {
  const [form, setForm] = useState<UserFormData>({
    firstName: "",
    lastName: "",
    email: defaultEmail ?? "",
    password: "",
    repeatPassword: ""
  });

  const handleSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    setDirtyFields(fields =>
      typedKeys(fields).reduce((acc, prop) => {
        acc[prop] = true;
        return acc;
      }, {} as typeof dirtyFields)
    );
    callback();
  };

  const [dirtyFields, setDirtyFields] = useState(
    typedKeys(form).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {} as { [P in keyof typeof form]: boolean })
  );

  const handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    event.persist();
    setForm(form => ({
      ...form,
      //@ts-ignore: has target
      [event.target.name]: event.target.value
    }));
    //@ts-ignore: has target
    return Promise.resolve(event.target.value);
  };

  const handleBlur = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    //@ts-ignore: event.target.name
    const targetName = event.target.name;
    setDirtyFields(dirtyFields => ({
      ...dirtyFields,
      //@ts-ignore: name
      [targetName]: true
    }));
  }, []);

  return { handleSubmit, handleChange, form, handleBlur, dirtyFields };
};
