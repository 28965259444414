import React, { useMemo, SyntheticEvent } from "react";
import { useSignupForm } from "../Hooks/UseSignupForm";
import Button from "./Button";
import FieldSet from "./FieldSet";
import UserFormData from "../Types/UserFormData";
import fadeSlideAnimation from "../animation/fadeSlideAnimation";
import useValidation, {
  required,
  email,
  minLength
} from "../Hooks/useValidation";
import checkmark from "../assets/check_circle.svg";

interface Props {
  email?: string;
  userExists?: boolean;
  onEmailChange: (value: string) => void;
  onSubmit: (form: UserFormData) => void;
}

const SignupForm = (props: Props) => {
  const onSubmit = () => {
    if (!formIsPostable) {
      return;
    }
    props.onSubmit(form);
  };

  const {
    form,
    handleSubmit,
    handleChange,
    dirtyFields,
    handleBlur
  } = useSignupForm(onSubmit, props.email);

  const errors = useValidation(
    {
      email: [
        required("Email is required"),
        email("Email must be formatted correctly")
      ],
      firstName: [required("First name is required")],
      lastName: [required("Last name is required")],
      password: [
        required("Please enter password"),
        minLength(length => `Password must be ${length} characters long`, 7)
      ],
      repeatPassword: [required("Enter repeat password")]
    },
    form
  );

  const repeatError = useMemo(
    () => form.password !== form.repeatPassword && "Passwords does not match",
    [form.password, form.repeatPassword]
  );
  const formIsPostable = props.userExists
    ? !errors["email"]
    : !(Object.keys(errors).length || repeatError);

  const handleEmailChange = (event: SyntheticEvent<HTMLInputElement>) => {
    handleChange(event).then(res => {
      props.onEmailChange(res);
    });
  };

  return (
    <form onSubmit={handleSubmit} style={{ margin: "1em 0" }}>
      <div style={fadeSlideAnimation()}>
        <FieldSet
          required={true}
          fieldType="email"
          fieldName="email"
          fieldValue={form.email}
          labelText="Email"
          onChange={handleEmailChange}
          error={dirtyFields.email && errors.email}
          icon={(props.userExists || undefined) && checkmark}
        />
      </div>
      {props.userExists != null && (
        <div key={`${props.userExists}`} style={fadeSlideAnimation()}>
          {!props.userExists ? (
            <>
              {/* <p>Enter name and password to create your United Spaces account</p> */}
              <FieldSet
                required={!props.userExists}
                fieldType="text"
                fieldName="firstName"
                fieldValue={form.firstName}
                labelText="First name"
                onChange={handleChange}
                onBlur={handleBlur}
                error={dirtyFields.firstName && errors.firstName}
              />
              <FieldSet
                required={!props.userExists}
                fieldType="text"
                fieldName="lastName"
                fieldValue={form.lastName}
                labelText="Last name"
                onChange={handleChange}
                onBlur={handleBlur}
                error={dirtyFields.lastName && errors.lastName}
              />
              <FieldSet
                autoComplete="off"
                required={!props.userExists}
                fieldType="password"
                fieldName="password"
                fieldValue={form.password}
                labelText="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                error={dirtyFields.password && errors.password}
              />
              <FieldSet
                autoComplete="off"
                required={!props.userExists}
                fieldType="password"
                fieldName="repeatPassword"
                labelText="Repeat password"
                fieldValue={form.repeatPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  dirtyFields.repeatPassword &&
                  (errors.repeatPassword || repeatError)
                }
              />
            </>
          ) : (
            <p>
              Yay! This email has an existing account at United Spaces. Connect
              your new pass access to this account.
            </p>
          )}
          <div style={{ margin: "2em 0", textAlign: "center" }}>
            <Button disabled={!formIsPostable} type="submit">
              {!props.userExists ? "Create account" : "Connect to account"}
            </Button>
          </div>
        </div>
      )}
    </form>
  );
};

export default SignupForm;
