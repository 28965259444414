const colorWithAlpha = (color: Color, alpha: number) => {
    return color.split(", 1)").join(`, ${alpha})`);
};

const colors = {
    pink: "rgba(250, 232, 230, 1)", // #fae8e6 - piglet
    pinkDark: "rgba(241, 216, 213, 1)", // #f1d8d5 - dustyPink
    blue: "rgba(38, 45, 77, 1)", // #262d4d - deepBlue
    blueDark: "rgba(31, 36, 62, 1)", // #1f243e - submarine
    purple: "rgba(99, 26, 193, 1)", // #631ac1 - easter
    purpleDark: "rgba(81, 24, 171, 1)", // #5118ab - grape
    grayLight: "rgba(248, 248, 248, 1)", // #f8f8f8
    gray: "rgba(233, 234, 237, 1)", // #e9eaed - cloud
    grayDark: "rgba(145, 150, 168, 1)", // #9196a8 - blueTack
    green: "rgba(55, 237, 97, 1)", // #37ed61
    red: "rgba(237, 67, 55, 1)", // #ed4337
    white: "rgba(255, 255, 255, 1)",
    black: "rgba(0, 0, 0, 1)"
} as const;

export type Color = typeof colors[keyof typeof colors];

export default { ...colors, colorWithAlpha };
